import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { DepartmentService } from 'app/core/service/department/department.service';

@Injectable({
    providedIn: 'root'
})
export class DepartmentDataResolver  {
    /**
     * Constructor
     */
    constructor(private _departmentService: DepartmentService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        return forkJoin([
            this._departmentService.getDepartmentsInitial(),
        ])

    }

}