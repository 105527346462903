import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { OrganizationDataResolver } from './modules/admin/manage-organization/organization.resolvers';
import { OrganizationDetailDataResolver } from './modules/admin/manage-organization/organization-details/organization-detail.resolvers';
import { ProjectDataResolver } from './modules/admin/manage-project/project.resolvers';
import { ProjectDetailDataResolver } from './modules/admin/manage-project/project-details/project-detail.resolvers';
import { SafetyEventTypeDataResolver } from './modules/admin/manage-event-type/event-type.resolvers';
import { SafetyEventClassificationDataResolver } from './modules/admin/manage-event-classification/event-classification.resolvers';
import { OrganizationDocumentDataResolver } from './modules/admin/manage-organization-document/organization-document.resolvers';
import { SafetyEventCategoryDataResolver } from './modules/admin/manage-event-category/event-category.resolvers';
import { AlertDataResolver } from './modules/admin/manage-alert/alert.resolvers';
import { InjuryTypeDataResolver } from './modules/admin/manage-injury-type/injury-type.resolvers';
import { RootCauseDataResolver } from './modules/admin/manage-root-cause/root-cause.resolvers';
import { TimeTargetDataResolver } from './modules/admin/manage-event-time-target/event-time-target.resolvers';
import { EventDataResolver } from './modules/admin/manage-event/event.resolvers';
import { CreateEventDataResolver } from './modules/admin/manage-event/create-event/create-event.resolvers';
import { EventDetailDataResolver } from './modules/admin/manage-event/event-details/event-details.resolvers';
import { SupportTicketDataResolver } from './modules/admin/manage-support-ticket/manage-support-ticket.resolvers';
import { ManageSupportTicketModule } from 'app/modules/admin/manage-support-ticket/manage-support-ticket.module';
import { SupportTicketDetailDataResolver } from './modules/admin/manage-support-ticket/support-ticket-details/support-ticket-detail.resolvers';
import { SupportTicketDetailModule } from 'app/modules/admin/manage-support-ticket/support-ticket-details/support-ticket-details.module';
import { DashboardDataResolver } from './modules/admin/dashboard/dashboard.resolvers';
import { ReportDataResolver } from './modules/admin/report/report.resolvers';
import { EmployeeDataResolver } from './modules/admin/manage-employee/employee.resolvers';
import { AdminDataResolver } from './modules/admin/manage-admins/admin.resolvers';
import { ClassificationDataResolver } from './modules/admin/manage-checklist-classification/checklist-classification.resolvers';
import { ChecklistDataResolver } from './modules/admin/manage-checklist/checklist.resolvers';
import { ChecklistItemDataResolver } from './modules/admin/manage-checklist-item/checklist-item.resolvers';
import { ChecklistSubmissionDataResolver } from './modules/admin/manage-checklist-submission/checklist-submission.resolvers';
import { SubmissionDetailResolver } from './modules/admin/submission-detail/submission-detail.resolvers';
import { SnagChartDataResolver } from './modules/admin/snag-chart/snag-chart.resolvers';
import { FactoryDataResolver } from './modules/admin/manage-factory/factory.resolvers';
import { RegionDataResolver } from './modules/admin/manage-region/region.resolvers';
import { DashboardAuthGuard } from './modules/admin/dashboard/auth.guard';
import { EventDetailGuestDataResolver } from './modules/admin/manage-event/event-details-guest/event-details-guest.resolvers';
import { DepartmentDataResolver } from './modules/admin/manage-department/department.resolvers';
// import { ReportPrintDataResolver } from './modules/admin/report/print-view/report-print.resolvers';
// import { ReportPrintViewComponent } from './modules/admin/report/print-view/print-view.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },
    
    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'event/:id',
                resolve: {
                    initialData: EventDetailGuestDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event/event-details-guest/event-details-guest.module').then(m => m.EventDetailGuestModule)
            },
            // {
            //     path: 'report/:token',
            //     resolve: {
            //         reportData: ReportPrintDataResolver,
            //     }, loadChildren: () => import('app/modules/admin/report/print-view/report-print.module').then(m => m.ReportPrintViewModule)
            // }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                canActivate: [DashboardAuthGuard],
                canActivateChild: [DashboardAuthGuard],
                resolve: { initialData: DashboardDataResolver },
                loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'custom-report',
                resolve: { initialData: ReportDataResolver },
                loadChildren: () => import('app/modules/admin/report/report.module').then(m => m.ReportModule)
            },
            {
                path: 'downloads',
                loadChildren: () => import('app/modules/admin/report/manage-report-requests/manage-report-requests.module').then(m => m.ManageReportRequestModule)
            },
            {
                path: 'snag-chart',
                resolve: { initialData: SnagChartDataResolver },
                loadChildren: () => import('app/modules/admin/snag-chart/snag-chart.module').then(m => m.SnagChartModule)
            },
            { path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule) },
            {
                path: 'organization',
                resolve: {
                    initialData: OrganizationDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-organization/manage-organization.module').then(m => m.ManageOrganizationModule)
            },
            {
                path: 'organization/:id',
                resolve: {
                    initialData: OrganizationDetailDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-organization/organization-details/organization-details.module').then(m => m.OrganizationDetailModule)
            },
            {
                path: 'project',
                resolve: {
                    initialData: ProjectDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-project/manage-project.module').then(m => m.ManageProjectModule)
            },
            {
                path: 'project/:id',
                resolve: {
                    initialData: ProjectDetailDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-project/project-details/project-details.module').then(m => m.ProjectDetailModule)
            },
            {
                path: 'employee',
                resolve: {
                    initialData: EmployeeDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-employee/manage-employee.module').then(m => m.ManageEmployeeModule)
            },
            {
                path: 'admin',
                resolve: {
                    initialData: AdminDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-admins/manage-admin.module').then(m => m.ManageAdminModule)
            },
            // {
            //     path: 'configuration/injury-type',
            //     resolve: {
            //         initialData: InjuryTypeDataResolver,
            //     }, loadChildren: () => import('app/modules/admin/manage-injury-type/manage-injury-type.module').then(m => m.ManageInjuryTypeModule)
            // },
            {
                path: 'configuration/root-cause',
                resolve: {
                    initialData: RootCauseDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-root-cause/manage-root-cause.module').then(m => m.ManageRootCauseModule)
            },
            {
                path: 'configuration/time-target',
                resolve: {
                    initialData: TimeTargetDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event-time-target/manage-event-time-target.module').then(m => m.ManageEventTimeTargetModule)
            },
            // {
            //     path: 'configuration/event/category',
            //     resolve: {
            //         initialData: SafetyEventCategoryDataResolver,
            //     }, loadChildren: () => import('app/modules/admin/manage-event-category/manage-event-category.module').then(m => m.ManageSafetyEventCategoryModule)
            // },
            // {
            //     path: 'configuration/notification-matrix',
            //     resolve: {
            //         initialData: NotificationMatrixDataResolver,
            //     }, loadChildren: () => import('app/modules/admin/manage-notification-matrix/notification-matrix.module').then(m => m.NotificationMatrixModule)
            // },
            {
                path: 'configuration/event/type',
                resolve: {
                    initialData: SafetyEventTypeDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event-type/manage-event-type.module').then(m => m.ManageSafetyEventTypeModule)
            },
            {
                path: 'configuration/event/classification',
                resolve: {
                    initialData: SafetyEventClassificationDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event-classification/manage-event-classification.module').then(m => m.ManageSafetyEventClassificationModule)
            },
            {
                path: 'document',
                resolve: {
                    initialData: OrganizationDocumentDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-organization-document/manage-organization-document.module').then(m => m.ManageOrganizationDocumentModule)
            },
            {
                path: 'alert',
                resolve: {
                    initialData: AlertDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-alert/manage-alert.module').then(m => m.ManageAlertModule)
            },
            {
                path: 'database',
                resolve: {
                    initialData: EventDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event/manage-event.module').then(m => m.ManageEventModule)
            },
            {
                path: 'database/category/:categoryId',
                resolve: {
                    initialData: EventDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event/manage-event.module').then(m => m.ManageEventModule)
            },
            // {
            //     path: 'database/new',
            //     resolve: {
            //         initialData: CreateEventDataResolver,
            //     }, loadChildren: () => import('app/modules/admin/manage-event/create-event/create-event.module').then(m => m.CreateEventModule)
            // },
            {
                path: 'database/new/:id',
                resolve: {
                    initialData: CreateEventDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event/create-event/create-event.module').then(m => m.CreateEventModule)
            },
            {
                path: 'database/:id',
                resolve: {
                    initialData: EventDetailDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-event/event-details/event-details.module').then(m => m.EventDetailModule)
            },
            {
                path: 'support/ticket',
                resolve: {
                    initialData: SupportTicketDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-support-ticket/manage-support-ticket.module').then(m => ManageSupportTicketModule)
            },
            {
                path: 'support/ticket/:id',
                resolve: {
                    initialData: SupportTicketDetailDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-support-ticket/support-ticket-details/support-ticket-details.module').then(m => SupportTicketDetailModule)
            },

            //Checklist            
            {
                path: 'checklist/classification',
                resolve: {
                    initialData: ClassificationDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-checklist-classification/manage-checklist-classification.module').then(m => m.ManageClassificationModule)
            },
            {
                path: 'checklist/list',
                resolve: {
                    initialData: ChecklistDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-checklist/manage-checklist.module').then(m => m.ManageChecklistModule)
            },
            {
                path: 'checklist/list/:id',
                resolve: {
                    initialData: ChecklistItemDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-checklist-item/manage-checklist-item.module').then(m => m.ManageChecklistItemsModule)
            },
            {
                path: 'checklist/list/:id/submissions',
                resolve: {
                    initialData: ChecklistSubmissionDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-checklist-submission/manage-checklist-submission.module').then(m => m.ManageChecklistSubmissionModule)
            },
            {
                path: 'checklist/list/:checklist/submissions/:submission',
                resolve: {
                    initialData: SubmissionDetailResolver,
                }, loadChildren: () => import('app/modules/admin/submission-detail/submission-detail.module').then(m => m.SubmissionDetailModule)
            },

            // Department management
            {
                path: 'department',
                resolve: {
                    initialData: DepartmentDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-department/manage-department.module').then(m => m.ManageDepartmentModule)
            },

            // Factory management
            {
                path: 'factory',
                resolve: {
                    initialData: FactoryDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-factory/manage-factory.module').then(m => m.ManageFactoryModule)
            },

            // Region management
            {
                path: 'region',
                resolve: {
                    initialData: RegionDataResolver,
                }, loadChildren: () => import('app/modules/admin/manage-region/manage-region.module').then(m => m.ManageRegionModule)
            },
        ]
    }
];
